export class TagInput {
  static setup() {
    $('.my-picker').on('click','.no-results',function(){
      const regex = /"([^"]*)"/;
      const match = this.innerText.match(regex);
      const newTag = match[1];
      $('#task_tag_list').append(TagInput.createTagOption(newTag));
      $('#task_tag_list').selectpicker('refresh');
    });
  }
  
  static createTagOption(newTag) {
    const content = `<span class='badge badge-primary'>${newTag}</span>`;
    return `<option value="${newTag}" selected="selected" data-content="${content}">${newTag}</option>`;
  }
}
