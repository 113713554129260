import $ from 'jquery';

export class RecentTasks {
  static setup() {
    this.filterRecentTasks();
  }


  static filterRecentTasks() {
    $('#recent-task-search').on('keyup input', function() {
      const search = $(this).val();
      if (search.length > 1) {
        $('.recent-task-item').removeClass('d-none');
        $('.recent-task-item').hide();
        $('.recent-task-item').each((_index, item) => {
          const taskName = $(item).data('task-name');
          if(taskName.toLowerCase().includes(search.toLowerCase())) {
            $(item).show();
          }
        });
      } else {
        $('.recent-task-item').show();
      }
    });
  }
}
