import $ from 'jquery';

export class NotesPage {
  static setup() {
    if ($('#fileupload').length > 0) {
      $('#fileupload input').on('change', function(_e) {
        return $(this).closest('form').submit();
      });
    }
  }
}
