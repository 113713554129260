import $ from 'jquery';

export class PaymentsPage {
  static setup() {
    $('#payment_client_id').change(function() {
      const select = $(this);
      return $.ajax(`/clients/${select.val()}`, {
        data: {
          'attributes': 'balance'
        },
        success(data, _status, _jqXHR) {
          return $('#payment_amount').val(data.balance);
        }
      });
    });
  }
}
