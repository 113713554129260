import $ from 'jquery';
import { TasksPage } from './tasksPage';
import { NotesPage } from './notesPage';
import { PaymentsPage } from './paymentsPage';
import { ChargesPage } from './chargesPage';
import { TagInput as TagsPage } from './tagInput';

export class Pages {
  static setup() {
    $(document).ready(() => {
      TasksPage.setup();
      NotesPage.setup();
      PaymentsPage.setup();
      Pages.setupTooltips();
      ChargesPage.setup();
      TagsPage.setup();
    });
  }

  static setupTooltips() {
    $('[data-toggle="tooltip"]').tooltip({ boundary: 'window' });
  }
}
